// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses60200: JSSThemeDefaultBonusesType = (themeVars) => {
  const {
    margins,
    colors: {
      colorCombinations: { text2Primary, text2White },
      colorTypes: { neutral1 },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        ...text2White,
        '&:hover, &.active': text2Primary,
      },
      tabsItemMobile: {
        ...text2White,
        borderRight: `1px solid ${neutral1}`,
        '&.active': text2Primary,
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: 0,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      img: {
        padding: [margins.md, margins.md, 0, 0],
      },
    },
  };
};
