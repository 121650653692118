// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60200';
import { JSSThemeHeaderMenu60200 } from './JSSThemeHeaderMenu60200';
import { JSSThemeMyBets60200 } from './JSSThemeMyBets60200';
import { JSSThemeSportsMenu60200 } from './JSSThemeSportsMenu60200';
import { JSSThemeBannerItems60200 } from './JSSThemeBannerItems60200';
import { JSSThemeForm60200 } from './JSSThemeForm60200';
import { JSSThemeBonuses60200 } from './JSSThemeBonuses60200';
import { JSSThemeSearch60200 } from './JSSThemeSearch60200';
import { JSSThemeEventSlider60200 } from './JSSThemeEventSlider60200';
import { JSSThemeOutcomes60200 } from './JSSThemeOutcomes60200';
import { JSSThemeHeader60200 } from './JSSThemeHeader60200';
import { JSSThemeLanguageSwitcher60200 } from './JSSThemeLanguageSwitcher60200';
import { JSSThemeAuthForm60200 } from './JSSThemeAuthForm60200';
import { JSSThemeCoupons60200 } from './JSSThemeCoupons60200';
import { JSSThemePromotions60200 } from './JSSThemePromotions60200';
import { JSSThemeSitemap60200 } from './JSSThemeSitemap60200';
import { JSSThemeBalance60200 } from './JSSThemeBalance60200';
import { JSSThemeCasino60200 } from './JSSThemeCasino60200';
import { JSSThemePopularLeagues60200 } from './JSSThemePopularLeagues60200';
import { JSSThemeAccountSection60200 } from './JSSThemeAccountSection60200';
import { JSSThemeLive60200 } from './JSSThemeLive60200';
import { JSSThemeDropdownMenu60200 } from './JSSThemeDropdownMenu60200';
import { JSSThemeBetgenius60200 } from './JSSThemeBetgenius60200';

export const colorTypes60200: ColorTypes = {
  ...colorTypes,
  primary: '#fece38',
  text1: '#8c8c8c',
  text2: '#606060',
  text3: '#1a1a1a',
  text4: '#c7c7c7', //available for a new color
  text5: '', //available for a new color
  background1: '#8c8c8c',
  background2: '#606060',
  background3: '#236d27',
  background4: '#ffe15f',
  background5: '#236d27', //available for a new color,
  background6: '#236d27', //available for a new color
  shadow: 'rgba(0, 0, 0, 0.3)', //black whith opacity for text shadow
  accent: '#207c23',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: '#fece38',
  inform: '#fece38',
  neutral1: '#f6f6f6',
  neutral2: '#9c9c9c',
  neutral3: '#565757',
  border1: '#eee',
  border2: '#c6c6c6',
};

const pick = ({
  text2Primary,
  text1Bg2,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  neutral1Neutral2,
  text3Neutral1,
  text2Neutral1,
  whiteAccent,
  whiteError,
  neutral1Bg1,
  text2White,
  text2Bg1,
  neutral1Bg2,
  whitePrimary,
  primaryBg1,
  text2Border1,
  bg2Primary,
  whiteText1,
}) => ({
  text2Primary,
  text1Bg2,
  whiteBg1,
  whiteBg2,
  whiteSuccess,
  neutral1Neutral2,
  text3Neutral1,
  text2Neutral1,
  whiteAccent,
  whiteBg3,
  whiteError,
  neutral1Bg1,
  text2White,
  text2Bg1,
  neutral1Bg2,
  whitePrimary,
  primaryBg1,
  text2Border1,
  bg2Primary,
  whiteText1,
});

export const colorCombinations60200 = getColorCombinations(
  colorTypes60200,
  pick
);

export const JSSThemeVariables60200: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations60200,
    colorTypes: colorTypes60200,
  },
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-italic-webfont',
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bolditalic-webfont',
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-black-webfont',
          fontWeight: '900',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-blackitalic-webfont',
          fontWeight: '900',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-light-webfont',
          fontWeight: '200',
          fontStyle: 'normal',
        },
      ],
    },
  },
  fontSizes: {
    xxxl: 36,
  },
};

export const JSSTheme60200: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: {
        text2Primary,
        text3Neutral1,
        text2Neutral1,
        neutral1Bg1,
        neutral1Neutral2,
        whiteAccent,
        whiteBg1,
        text2White,
      },
      colorTypes: { border2, white, primary, text2, text1, text4, neutral3 },
    },
  } = themeVars;
  return {
    mybets: JSSThemeMyBets60200(themeVars),
    outcomes: JSSThemeOutcomes60200(themeVars),
    Button: {
      root: {
        lineHeight: '32px',
        fontSize: fontSizes.lg,
        padding: [0, margins.md * 1.5],
      },
      default: {
        ...getButtonColors(text2White),
        '@media (pointer: coarse)': {
          background: text1,
          color: 'white',
          '&:hover': {
            color: white,
          },
        },
      },
      primary: {
        ...text2Primary,
        '&:hover': {
          background: neutral3,
        },
      },
      secondary: {
        ...whiteBg1,
        '@media (pointer: coarse)': {
          background: primary,
          color: text2,
        },
        '&:hover': {
          color: white,
          background: neutral3,
        },
      },
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '38px',
        fontSize: themeVars.fontSizes.xl,
      },
      small: {
        padding: [themeVars.margins.xs, themeVars.margins.md],
      },
      linkSecondary: {
        color: text1,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkAccent: {
        color: text4,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
    },
    header: JSSThemeHeader60200(themeVars),
    headerMenu: JSSThemeHeaderMenu60200(themeVars),
    betslip: betslip(themeVars),
    live: JSSThemeLive60200(themeVars),
    sportsMenu: JSSThemeSportsMenu60200(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60200(themeVars),
    LiveCalendar: {
      common: {
        margin: margins.md,
      },
      h2: {
        ...text3Neutral1,
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
      },
      dateTitle: {
        ...text2Neutral1,
        borderBottom: `1px solid ${colorTypes60200.border1}`,
        fontSize: fontSizes.md,
      },
      dateEvent: {
        ...text2Neutral1,
        borderBottom: `1px solid ${colorTypes60200.border1}`,
        fontSize: fontSizes.md,
        '&:hover': text2Primary,
      },
      link: {
        color: text2,
      },
    },
    NoBetsMessage: {
      root: neutral1Bg1,
    },
    BetslipMyBetsPanelTitle: {
      heading: neutral1Neutral2,
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        borderTop: `1px solid ${border2}`,
      },
      stakePotential: {
        color: white,
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    banners: JSSThemeBannerItems60200(themeVars),
    form: JSSThemeForm60200(themeVars),
    bonuses: JSSThemeBonuses60200(themeVars),
    coupons: JSSThemeCoupons60200(themeVars),
    search: JSSThemeSearch60200(themeVars),
    eventSlider: JSSThemeEventSlider60200(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60200(themeVars),
    authForm: JSSThemeAuthForm60200(themeVars),
    promotions: JSSThemePromotions60200(themeVars),
    sitemap: JSSThemeSitemap60200(themeVars),
    balance: JSSThemeBalance60200(themeVars),
    popularLeagues: JSSThemePopularLeagues60200(themeVars),
    casino: JSSThemeCasino60200(themeVars),
    accountSection: JSSThemeAccountSection60200(themeVars),
    betgenius: JSSThemeBetgenius60200(themeVars),
  };
};

export const getJSSThemePatterns60200: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { text2Primary, whiteBg1, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(text2Primary),
      secondary: getButtonColors(whiteBg1),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '38px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
