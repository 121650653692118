import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60200: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background1, text2, white },
    },
  } = themeVars;
  return {
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: background1,
      },
    },
    BetsHistory: {
      filterSelectionItem: {
        color: text2,
        '&:hover': {
          color: white,
        },
      },
      filterSelectionItemActive: {
        color: white,
      },
    },
    OPMPageComponent: {
      heading: {
        margin: [10, 0, 10, 10],
      },
    },
  };
};
