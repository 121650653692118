// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60200: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: primary,
      },
      authContentWrap: {
        justifyContent: 'flex-start',
      },
    },
    LogoBrand: {
      logoLink: {
        height: 43,
      },
      logoWrapper: {
        width: 240,
        '@media screen and (max-width: 1500px)': {
          width: 200,
          flexShrink: 0,
        },
        '@media screen and (max-width: 1230px)': {
          width: 150,
          flexShrink: 0,
        },
      },
      logo: {
        width: '100%',
      },
    },
  };
};
