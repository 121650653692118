// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60200,
  JSSThemeVariables60200,
  getJSSThemePatterns60200,
} from './JSSTheme60200';
import { GeoblockPage60200 } from './GeoblockPage60200';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60200').then((module) => module.FullDesktop60200),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60200').then((module) => module.FullMobile60200),
  }),
  JSSTheme: JSSTheme60200,
  JSSThemeVariables: JSSThemeVariables60200,
  JSSThemePatterns: getJSSThemePatterns60200,
  GeoblockPage: GeoblockPage60200,
});
