import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu60200: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: { text2Primary },
      colorTypes: { neutral2, border2, text2 },
    },
  } = themeVars;

  return {
    SportsMenuDesktop: {
      sport: {
        '&:hover, &:focus, &.active': text2Primary,
      },
      region: {
        '&:hover, &:focus, &.active': text2Primary,
      },
    },
    SportsMenuMobile: {
      region: {
        '&.active': {
          ...text2Primary,
          '&:hover': text2Primary,
        },
      },
    },
    SubMenuListUIMobile: {
      heading: {
        color: border2,
        fontSize: fontSizes.m,
      },
      linkDescription: {
        fontSize: fontSizes.lg,
      },
    },
    HorizontalSportsListIconBig: {
      sportName: {
        color: text2,
        '&.active': {
          color: neutral2,
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      sportIcon: {
        color: text2,
        '&.active': {
          color: neutral2,
        },
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: 'transparent',
      },
    },
    HorizontalSportsListUISmallIcons: {
      sportLink: {
        padding: [margins.xs, 0],
        '&.active, &:hover': {
          color: neutral2,
        },
      },
      item: {
        padding: [0, margins.md],
        borderRight: '1px solid',
        borderColor: border2,
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        margin: [0, margins.xm],
      },
    },
  };
};
