// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60200: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { neutral1Bg1, whiteBg2, text2Primary },
      colorTypes: { text2 },
    },
  } = themeVars;
  return {
    ConfirmationBox: {
      text: {
        color: text2,
      },
      confirmation: {
        color: text2,
      },
    },
    CashOut: {
      success: {
        color: text2,
      },
    },
    MyBets: {
      content: neutral1Bg1,
      myBetsTabsItem: {
        ...whiteBg2,
        '&:hover, &.active': text2Primary,
      },
    },
  };
};
