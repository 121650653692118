// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes60200: JSSThemeDefaultOutcomesType = (
  themeVars
) => {
  const {
    fontSizes,
    colors: {
      colorTypes: { text3, background1, text2 },
    },
  } = themeVars;

  return {
    MobileOutcome: {
      root: {
        color: text3,
        fontSize: fontSizes.sm,
        '&.active': {
          background: background1,
        },
      },
      priceValue: {
        color: text2,
      },
    },
  };
};
