// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60200: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text2White, text2Border1 },
      colorTypes: { neutral2 },
    },
    fontSizes,
  } = themeVars;
  return {
    Input: {
      root: {
        ...text2White,
        '&::placeholder': {
          color: neutral2,
        },
        'input:-webkit-autofill': {
          fontSize: fontSizes.md,
        },
        'input:-webkit-autofill:hover': {
          fontSize: fontSizes.md,
        },
        'input:-webkit-autofill:focus': {
          fontSize: fontSizes.md,
        },
      },
      betslipInput: {
        ...text2Border1,
        fontSize: fontSizes.md,
      },
    },
  };
};
