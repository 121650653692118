// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino60200: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white, neutral2, text2, neutral1 },
      colorCombinations: { text2Primary },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: text2,
        position: 'static',
        overflowX: 'auto',
        height: 'auto',
      },
      menuItem: {
        padding: '4px 14px',
        color: white,
        '&.active, &:hover': {
          ...text2Primary,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...text2Primary,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: white,
          },
        }),
      },
      iconChevron: {
        fontSize: fontSizes.xs,
        marginLeft: margins.xs,
      },
    },
    CasinoContainer: {
      searchWrap: {
        display: 'flex',
        zIndex: 10,
      },
      providersFiltersWrap: {
        background: neutral1,
        top: 42,
      },
    },
    SearchBox: {
      inputWrap: {
        color: neutral2,
        minWidth: 170,
        margin: margins.xs,
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        position: 'static',
      },
      searchResult: {
        top: 42,
      },
      inputSearch: {
        width: '100%',
        height: '100%',
        maxWidth: 230,
        color: neutral2,
        padding: [0, margins.md],
        background: white,
        '&::placeholder': {
          color: neutral2,
        },
      },
      iconSearch: {
        background: white,
      },
    },
  };
};
